import React from 'react'

function QuickCount({count}) {
    console.log("====count=====",count)
    return (
        <section className="QuickCountSec">
            <div className="container">
                <div className="row">
                    <div className="col-md-3 col-6" data-aos="fade-down">
                        <span>Category</span>
                        <strong>{count?.category}+</strong>
                    </div>
                    <div className="col-md-3 col-6" data-aos="fade-down">
                        <span>Course</span>
                        <strong>{count?.course}+</strong>
                    </div>
                    <div className="col-md-3 col-6" data-aos="fade-down">
                        <span>Instructor</span>
                        <strong>{count?.tutor}+</strong>
                    </div>
                    <div className="col-md-3 col-6" data-aos="fade-down">
                        <span>Students</span>
                        <strong>{count?.students}+</strong>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default QuickCount