import React from 'react'
import InnerPageHeader from '../../common/InnerPageHeader'
import UserDetails from '../../components/Student/Account/UserDetails'
import CourseDetails from '../../components/Student/Account/CourseDetails'
import EditProfile from '../../components/Student/Account/EditProfile'
import PurchasedCourses from '../../components/Student/Account/PurchasedCourses'
import RecommendedCourses from '../../components/Student/Account/RecommendedCourses'
import ProfileApis from '../../queries/profile'

function Account() {
  const profileData = ProfileApis.GetUserProfile()
  const learningReportData = ProfileApis.LearningReport()

  return (
    <>
      <InnerPageHeader
        title="My Account"
        subtitile='My Learnings'
        titlePath='my-account'
        subTitlePath='my-learnings'
      />
      <section class="my_account_section" data-aos="fade-right" >
        <div class="container">
          <UserDetails
            api={profileData}
            profileData={profileData?.data?.data}
            learningReportData={learningReportData?.data?.data}
          />
          {
            profileData?.data?.data.currentVideo &&
            <CourseDetails profileData={profileData?.data?.data} />
          }

          <EditProfile
            api={profileData}
            profileData={profileData?.data?.data} />


          <PurchasedCourses
            api={profileData}
            profileData={profileData?.data?.data} />



        </div>
      </section>
      <RecommendedCourses profileData={profileData?.data?.data} />


    </>
  )
}

export default Account