import React from 'react';
import ReactDOM from 'react-dom/client';
// import './index.css';
import './assets/css/style.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './redux/store';
import { AuthProvider } from './context/AuthContext';
import { QueryClient,QueryClientProvider } from '@tanstack/react-query'
import RootContextProvider from './context/RootContextProvider';
import { Toaster } from 'react-hot-toast';
import CustomToaster from './common/CustomToaster';
import './i18n';
import { SubmitHandlerProvider } from './context/PaymentSubmitContext';
const root = ReactDOM.createRoot(document.getElementById('root'));
const queryClient = new QueryClient()

root.render(
  // <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <RootContextProvider>
          <CustomToaster/>
          <App />
        </RootContextProvider>
      </Provider>
    </QueryClientProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
