import React, { Fragment, useEffect, useState } from 'react'
import InnerPageHeader from '../../common/InnerPageHeader'
import Stripe from './Stripe'
import PaymentApis from '../../queries/payment'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSubmitHandler } from '../../context/PaymentSubmitContext'
import toast from 'react-hot-toast'

function PaymentGatewayContainer() {
    const { submitRef, paymentLoading } = useSubmitHandler();
    const [activeTab, setactiveTab] = useState()
    const navigate = useNavigate()
    const [stripePaymentDetails, setstripePaymentDetails] = useState({
        publicKey: '',
        clientSecretKey: ''
    })
    const { data: paymentGateways } = PaymentApis.GetpaymentGateways()
    const createPaymentIntent = PaymentApis.CreateStripeIntent()
    const [activePayment, setactivePayment] = useState('')

    const handlePaymentTabClick = async (id, slug) => {
        setactiveTab(id)
        setactivePayment(slug)
        console.log("In handleSubmit, submitRef:", submitRef);
        if (slug === "stripe") {
            const publicKey = paymentGateways?.data?.find((obj) => obj?.slug === "stripe")?.publicKey
            const body = {
                amount: 100,
                type: "course_purchase"
            }
            const response = await createPaymentIntent.mutateAsync(body)
            if (response?.status) {
                setstripePaymentDetails({
                    ...stripePaymentDetails,
                    clientSecretKey: response?.data?.clientSecret,
                    publicKey: publicKey,
                })
            }

        }
    }
    useEffect(()=>{
        if(!submitRef?.current){
            navigate('/cart')
        }
    },[submitRef?.current])
    const handleBack = () => {
        navigate('/cart')
    }
    const handleSubmit = async (paymentResponse) => {
        if (submitRef?.current) {
            try {
                await submitRef.current(activeTab, paymentResponse);
            } catch (error) {
                console.error("Submission error:", error);
                toast.error("Submission failed");
            }
        } else {
            toast.error("Submit handler not defined");
        }
    };


    const { clientSecretKey, publicKey } = stripePaymentDetails
    return (
        <div>
            <InnerPageHeader title="Payment" />
            <section className="home_category_section">
                <div className="container">

                    <div className="payment-gateways">
                        <div className="row justify-content-center">
                            <div className="payment-gateway-centerBox">
                                <div className="payment-gateways-Box">
                                    <div className="row align-items-center">

                                        <div className="col-lg-12 col-md-12 border-prf-left">
                                            <div className="row">
                                                <div className="col-md-4 pe-0">
                                                    <div
                                                        className="nav nav-tabs payment_tab_reg"
                                                        id="nav-tab"
                                                        role="tablist"
                                                    >
                                                        {paymentGateways?.data?.map((tab) => (
                                                            <Fragment key={tab.id}>
                                                                <button
                                                                    key={tab.id}
                                                                    className={`nav-link  ${activeTab === tab.id ? "active" : ""}`}
                                                                    id={`nav-${tab.id}-tab`}
                                                                    data-bs-toggle="tab"
                                                                    data-bs-target={`#nav-${tab.id}`}
                                                                    type="button"
                                                                    role="tab"
                                                                    aria-controls={`nav-${tab.id}`}
                                                                    onClick={() =>
                                                                        handlePaymentTabClick(tab.id, tab.slug)
                                                                    }
                                                                >
                                                                    <i className={tab?.icon ?? 'fa-solid fa-money-bill'}></i>{" "} {tab.slug}
                                                                </button>
                                                            </Fragment>
                                                        ))}
                                                    </div>
                                                </div>
                                                <div className="col-md-8 ps-0">
                                                    <div
                                                        className="tab-content p-3 border mb-5 payment__tab_cnt"
                                                        id="nav-tabContent"
                                                    >
                                                        {activeTab === undefined && (
                                                            <p>
                                                                <strong>
                                                                    select the preferred payment and place your order
                                                                    {/* {t("preferredPaymentPlaceholder")} */}
                                                                </strong>
                                                            </p>
                                                        )}
                                                        {paymentGateways?.data?.map((tab) => (
                                                            <div
                                                                key={tab.id}
                                                                className={`tab-pane fade ${activeTab === tab.id ? "show active" : ""
                                                                    } `}
                                                                id={`nav-${tab.id}`}
                                                            >

                                                                {tab.slug === "stripe" && clientSecretKey && (
                                                                    <Stripe
                                                                        clientSecret={clientSecretKey}
                                                                        handleFinish={handleSubmit}
                                                                        publicKey={publicKey}
                                                                        loading={paymentLoading}
                                                                    />
                                                                )}
                                                                {tab.slug === "free-join" && (
                                                                    <h6>Click the <strong>FINISH</strong> button and complete the process</h6>
                                                                )}

                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                activePayment != "stripe" &&
                                                <div className="BackBtn">
                                                    <button
                                                        className="plan-choose-back renew_btn mt-2"
                                                        onClick={handleBack}
                                                    >
                                                        Back

                                                    </button>

                                                    <button
                                                        type="submit"
                                                        className="plan-choose renew_btn mt-2"
                                                        disabled={paymentLoading}
                                                        onClick={()=>handleSubmit('')}

                                                    >
                                                        {
                                                            paymentLoading?
                                                            'Processing....'
                                                            :'Finish'

                                                        }

                                                    </button>


                                                </div>
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section >

        </div>
    )
}

export default PaymentGatewayContainer