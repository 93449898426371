import React from 'react'
import InnerPageHeader from '../../common/InnerPageHeader'
import contactUsimg from '../../assets/images/5100570.jpg'
import { Icon } from '@iconify/react';
import AuthApis from '../../queries/auth';
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import ContactUsschema from '../../schema/contactUsdetails';
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
function ContactUs() {
  const {
    register,
    handleSubmit,
    trigger,
    reset,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(ContactUsschema),
  })
  const { data: companyDetails } = AuthApis.GetcompanyDetails()
  const navigate=useNavigate()
  const contactSupport = AuthApis.Contact()
  const onSubmit = async (data) => {

    const isValid = await trigger()
    if (!isValid) return false
    const response = await contactSupport.mutateAsync(data)
    if (response.status) {
      reset()
      toast.success("message send")
      navigate('/login')

    } else {
      setError(response?.error?.field, { message: response?.error?.message })
    }
    console.log(data);
  };
  return (
    <>
      <InnerPageHeader title="Contact Us" />
      <section className="home_category_section">
        <div className="container">
          <div className="main_heading_txt">
            <div className="row align-items-center">
              <div className="col-md-6" data-aos="fade-up">
                <h2>
                  <strong>Get in Touch with Us</strong>
                </h2>
                <span><Icon icon="material-symbols-light:mark-email-unread-outline-sharp" width="24" height="24" /></span>{" "}
                <span>{companyDetails?.data?.email}</span>
                <img src={contactUsimg} className='w-100' />
              </div>
              <div className="col-md-6">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="mb-3">
                    <label for="exampleFormControlInput1" class="form-label">Name</label>
                    <input type="text" {...register("name")} className={`form-control ${errors.name ? 'is-invalid' : ''}`} id="exampleFormControlInput1" placeholder="Enter your name" />
                    <div class="invalid-feedback">
                      {errors?.name?.message}
                    </div>
                  </div>
                  <div className="mb-3">
                    <label for="exampleFormControlInput1" class="form-label">Email</label>
                    <input type="email" {...register("email")} className={`form-control ${errors.email ? 'is-invalid' : ''}`} id="exampleFormControlInput1" placeholder="your@example.com" />
                    <div class="invalid-feedback">
                      {errors?.email?.message}
                    </div>
                  </div>
                  <div class="mb-3">
                    <label for="exampleFormControlTextarea1" class="form-label">Message</label>
                    <textarea  {...register("message")} className={`form-control ${errors.message ? 'is-invalid' : ''}`} id="exampleFormControlTextarea1" rows="3"></textarea>
                    <div class="invalid-feedback">
                      {errors?.message?.message}
                    </div>
                  </div>
                  <button className="btn btn-primary" type="submit">Submit</button>
                </form>

              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default ContactUs