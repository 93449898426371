import React from 'react'
import Loginvector from '../../assets/images/login-vector.png'
import { Link } from 'react-router-dom'
import AuthApis from '../../queries/auth'
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import Registerschema from '../../schema/registration'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'


function Register() {
    const {
        register,
        handleSubmit,
        trigger,
        reset,
        setError,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(Registerschema),
    })
    const RegisterUser = AuthApis.RegisterUser()
    const navigate = useNavigate()

    const onSubmit = async (data) => {

        const isValid = await trigger()
        if (!isValid) return false
        data["role"] = "student"
        data["loginType"] = "gmail"
        const response = await RegisterUser.mutateAsync(data)
        if (response.status) {
            reset()
            toast.success("registration successfull")
            navigate('/login')

        } else {
            setError(response?.error?.field, { message: response?.error?.message })
        }
        console.log(data);
    };

    return (
        <section className="login_section" data-aos="fade-right">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-8">
                        <div className="login_box">
                            <div className="login_box_left_sc">
                                <h3>
                                    <span>Welcome!</span><br />
                                    Register Now
                                </h3>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="form-floating mb-3">
                                        <input type="text" {...register("firstName")} className={`form-control ${errors.firstName ? 'is-invalid' : ''}`} id="floatingInputName" placeholder="name@example.com" />

                                        <label htmlFor="floatingInputName">First Name</label>
                                        <div class="invalid-feedback">
                                            {errors?.firstName?.message}
                                        </div>
                                    </div>
                                    <div className="form-floating mb-3">
                                        <input type="text" {...register("lastName")} className={`form-control ${errors.lastName ? 'is-invalid' : ''}`} id="floatingInputLocation" placeholder="name@example.com" />
                                        <label htmlFor="floatingInputLocation">Last Name</label>
                                        <div class="invalid-feedback">
                                            {errors?.lastName?.message}
                                        </div>
                                    </div>
                                    <div className="form-floating mb-3">
                                        <input type="email" {...register("email")} className={`form-control ${errors.email ? 'is-invalid' : ''}`} id="floatingInputEmail" placeholder="name@example.com" />
                                        <label htmlFor="floatingInputEmail">Email address</label>
                                        <div class="invalid-feedback">
                                            {errors?.email?.message}
                                        </div>
                                    </div>
                                    <div className="form-floating mb-3">
                                        <input type="text" {...register("mobile")} className={`form-control ${errors.mobile ? 'is-invalid' : ''}`} id="floatingInputMobile" placeholder="name@example.com" />
                                        <label htmlFor="floatingInputMobile">Mobile No</label>
                                        <div class="invalid-feedback">
                                            {errors?.mobile?.message}
                                        </div>
                                    </div>

                                    <div className="form-floating mb-3">
                                        <input type="password" {...register("password")} className={`form-control ${errors.password ? 'is-invalid' : ''}`} id="floatingPassword" placeholder="Password" />
                                        <label htmlFor="floatingPassword">Password</label>
                                        <div class="invalid-feedback">
                                            {errors?.password?.message}
                                        </div>
                                    </div>

                                    <button className="btn btn-primary" type="submit">Register</button>
                                </form>
                                <div className="login_with_sec">
                                    <div className="login_with_head"><span>or Register</span></div>
                                    <div className="login_with_sec_ico_row">
                                        <a href="#" className="login_with_sec_ico"><i className="fa-brands fa-facebook-f"></i></a>
                                        <a href="#" className="login_with_sec_ico"><i className="fa-brands fa-google"></i></a>
                                    </div>
                                </div>
                            </div>
                            <div className="login_box_right_sec text-center">
                                <h3>
                                    Already Have an <br />
                                    Account?
                                </h3>
                                <Link to='/login' className="btn btn-primary px-4">Login</Link>
                                <div className="loginRegisterImage">
                                    <img className="img-fluid" src={Loginvector} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Register