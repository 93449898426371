import React from 'react'
import { Link } from 'react-router-dom'
import { useAuth } from '../context/AuthContext'
function InnerPageHeader({ title, subtitile, titlePath, subTitlePath }) {
  const {isAuthenticated}=useAuth()
  return (
   
    <section class="inner_banner_section2">
      <div class="container">
        <div class="my_account_banner_cnt">
          <h2>{title}</h2>
          {
            (subtitile &&isAuthenticated)&&
            <span>
              <Link to={`/${titlePath}`} class="active">{title}</Link> |
              <Link to={`/${subTitlePath}`}>{subtitile}</Link>
            </span>
          }
        </div>
      </div>
    </section>

  )
}

export default InnerPageHeader