import React from 'react'
import { useForm } from 'react-hook-form'
import ProfileApis from '../../../queries/profile'
import toast from 'react-hot-toast'
import Skeleton from 'react-loading-skeleton'
function LanguageSettings({ api, profileData }) {
    const {
        register,
        handleSubmit,
        trigger,
        formState: { errors },
        watch,
    } = useForm({
        defaultValues: {
            language: profileData?.user_detail?.language
        },

    })

    const { data: languages,isLoading: apiLoading } = ProfileApis.GetLanguages()
    const updateLanguage = ProfileApis.UpdateLanguage()
    const formValues = watch()
    const languageHandler = async (data) => {
        const isValid = await trigger()
        if (!isValid) return toast.error('check your values')
        const response = await updateLanguage.mutateAsync(data)
        if (response.status) {
            toast.success("language updation successfull")
            api.refetch()
        } else {
            toast.error("language updation failed")
        }
    }
    return (
        <div className="row">
            {
                apiLoading ?
                    <div className='col-md-6'>
                        <Skeleton height={50} />
                    </div>
                    :
                    <div className="col-md-6">
                        <div className="form-floating mb-3">
                            <select className={`form-select ${errors.countryId ? 'is-invalid' : ''}`} {...register('language')} id="floatingSelect" aria-label="Floating label select example">
                                <option value="">select</option>
                                {
                                    languages?.data &&
                                    languages?.data.map((items, index) => {
                                        return (
                                            <option value={items.value} selected={items.value === formValues.language}>{items.title}</option>
                                        )
                                    })
                                }


                            </select>
                            <label htmlFor="floatingInput">Languages</label>
                        </div>
                    </div>
            }
            <div className="row">
                {
                    apiLoading ?
                        <div className='col-md-5'>
                            <Skeleton className='btn btn-primary' width={50} />
                        </div>
                        :
                        <div className="col-md-5">
                            <button className="btn btn-primary" onClick={handleSubmit(languageHandler)}>SAVE</button>
                        </div>
                }
            </div>
            {/* <div className="col-md-6">
                <div className="form-floating mb-3">
                    <input type="text" className="form-control" id="floatingInput" placeholder="name@example.com" />
                    <label htmlFor="floatingInput">Email</label>
                </div>
            </div> */}
        </div>
    )
}

export default LanguageSettings