import React from 'react'
import bannerImg from '../../assets/images/banner-image.png'
import { Link } from 'react-router-dom'
function Banner() {
    return (
        <section className="banner_section">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-6">
                        <h1>
                            Learn Your <strong>Desired Skill</strong>
                            <strong>From The <span>Experts</span></strong>
                        </h1>
                        <p>
                            Discover a world of knowledge and opportunities with our online education platform. Pursue a new career.
                        </p>
                        <div className="d-flex gap-3">
                            <Link to='courses' className="btn btn-primary">
                                View All Course <i className="fa-solid fa-arrow-right"></i>
                            </Link>
                            <Link to="login" className="btn btn-secondary">Learn More</Link>
                        </div>
                    </div>
                    <div className="col-md-6 banner-image">
                        <img src={bannerImg} alt="" />
                    </div>
                </div>
            </div>
        </section>

    )
}

export default Banner