import api from "../../api/axios.instance"

export const getUserProfile = async () => {
    try {
        const response = await api.get('common/profile')
        return response
    } catch (error) {
        throw error
    }
}

export const editPersonalDetails = async (data) => {
    try {
        const response = await api.put('common/profile', data)
        return response
    } catch (error) {
        return error
    }
}

export const getCountries = async () => {
    try {
        const response = await api.get('common/countries')
        return response
    } catch (error) {
        return error
    }
}

export const getStates = async (countryId) => {
    try {
        const response = await api.get(`common/states?countryId=${countryId}`)
        return response
    } catch (error) {
        return error
    }
}
export const getLanguages = async () => {
    try {
        const response = await api.get('common/language')
        return response
    } catch (error) {
        return error
    }
}

export const updateLanguage = async (data) => {
    try {
        const response = await api.post('common/language', data)
        return response
    } catch (error) {
        return error
    }
}

export const getBadges = async () => {
    try {
        const response = await api.get('common/user-badges')
        return response
    } catch (error) {
        return error
    }
}
export const getPurchasedCourses = async (length, page) => {
    try {
        const response = await api.get('student/course-list', { params: { length, page } })
        return response
    } catch (error) {
        return error
    }
}

export const updateProfilePic = async (data) => {
    try {
        const response = await api.post('common/profile-photo', data)
        return response
    } catch (error) {
        return error
    }
}

export const dltProfilePic = async () => {
    try {
        const response = await api.delete('common/profile-photo')
        return response
    } catch (error) {
        return error
    }
}
export const purchasedCourseDetails = async () => {
    try {
        const response = await api.get('student/purchased-courses')
        return response
    } catch (error) {
        return error
    }
}
export const learningReport = async () => {
    try {
        const response = await api.get('student/learning-report')
        return response
    } catch (error) {
        return error
    }
}

export const verifyMail = async () => {
    try {
        const response = await api.post('common/verify-mail')
        return response
    } catch (error) {
        return error
    }
}
export const verifyMailOtp = async (data) => {
    try {
        const response = await api.post('common/verify-mail-otp', data)
        return response
    } catch (error) {
        return error
    }
}