import React, { useEffect } from 'react'
import Aos from 'aos';
import Header from '../common/Header';
import Footer from '../common/Footer';
import { Outlet } from 'react-router-dom';
import CommonApis from '../queries/common';
function MainLayout() {

  useEffect(() => {
    Aos.init()
  }, []);
  const { data: userDetails, refetch: getCommonDetails } = CommonApis.GetUserData()

  return (
    <>
      <Header userDetails={userDetails?.data} />
      <Outlet context={{ userDetails, getCommonDetails }} />
      <Footer />
    </>
  )
}

export default MainLayout