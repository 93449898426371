import api from "../../api/axios.instance"

export const getCourses = async (categoryId, limit, page) => {
    try {
        const response = await api.get('course', { params: { categoryId, limit, page } })
        return response
    } catch (error) {
        return error
    }
}

export const viewCourseDetails = async (courseId) => {
    try {
        const response = await api.get('course-list-single', { params: { courseId } })
        return response
    } catch (error) {
        return error
    }
}

export const addToCart = async (data) => {
    try {
        const response = await api.post('student/cart', data)
        return response
    } catch (error) {
        return error
    }
}
export const getCartItems = async () => {
    try {
        const response = await api.get('student/cart')
        return response
    } catch (error) {
        return error
    }
}

export const dltCartItems = async (courseId) => {
    try {
        const response = await api.delete('student/cart', { params: { courseId } })
        return response
    } catch (error) {
        return error
    }
}
export const purchaseCourse = async (data) => {
    try {
        const response = await api.post('student/purchase', data)
        return response
    } catch (error) {
        return error
    }
}
export const getCategories = async () => {
    try {
        const response = await api.get('categories')
        return response
    } catch (error) {
        return error
    }
}
export const getInvoiceDetails = async (invoice) => {
    try {
        const response = await api.get('common/purchase-invoice', { params: { invoice } })
        return response
    } catch (error) {
        return error
    }
}