import React from 'react'

function CourseRequirement({courseDetails}) {
    return (
        <>
            <h5>Course Requirements</h5>
            <p>{courseDetails?.data?.course?.requirements??"lorem ipsum"}</p>
        </>
    )
}

export default CourseRequirement