import React from 'react'
import SingleView from '../../components/Student/Learnings/SingleView'
import { useParams } from 'react-router-dom'
import LearningApis from '../../queries/learning'

function LearningsSingleView() {

    const { id } = useParams()
    const { data: learningData, refetch: refetchLearningData, isLoading: apiLoading } = LearningApis.GetLearningVideo(id)
    const { data: learningNotes } = LearningApis.GetLearningNotes(id)
    return (
        
        <SingleView
            learningData={learningData}
            refetchLearningData={refetchLearningData}
            learningNotes={learningNotes}
            courseId={id}
            apiLoading={apiLoading}
        />
    )
}

export default LearningsSingleView