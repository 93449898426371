import React, { useEffect, useState } from 'react'
import Vimeo from '@u-wave/react-vimeo';
import videoPlayerImg from '../../../assets/images/video-playericon.png'
import LearningApis from '../../../queries/learning';
import { useLocation, useNavigate } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'

function CourseVideoSection({ learningData, refetchLearningData, courseId, apiLoading, setTaskId }) {

    const VideoProgress = LearningApis.VideoProgress()
    const CurrentVideo = LearningApis.CurrentVideo()
    const CompleteTask = LearningApis.CompleteTask()
    const [videoProgress, setvideoProgress] = useState(0)
    const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
    const { state } = useLocation()
    const navigate = useNavigate()
    useEffect(() => {
        if (state?.taskId) {
            const Vidindex = learningData?.data?.taskList?.findIndex((video) => video.id === state?.taskId);
            setCurrentVideoIndex(Vidindex)

        } else return
    }, [state])
    const videoProgressHandler = (event) => {
        const { seconds } = event;
        // setvideoProgress(seconds);
        console.log(`Time played: ${seconds} seconds`);
    };

    const handleVideoChange = async (newIndex) => {
        const newVideo = learningData?.data?.taskList[newIndex];
        let payload = {
            taskId: learningData?.data?.taskList?.[currentVideoIndex].id,
            progress: videoProgress,
            // nextTaskId: newVideo?.id || null,
        };

        const response = await VideoProgress.mutateAsync(payload);
        if (response.status) {
            setvideoProgress(learningData?.data?.taskList?.[newIndex]?.progress)
            setCurrentVideoIndex(newIndex);

            await refetchLearningData();
        }
    };

    const nextHandler = () => {
        if (currentVideoIndex < learningData?.data?.taskList.length - 1) {
            handleVideoChange(currentVideoIndex + 1);
            navigate(`/my-learnings/${courseId}`, { state: null });
        }
    };

    const previousHandler = () => {
        if (currentVideoIndex > 0) {
            handleVideoChange(currentVideoIndex - 1);
            navigate(`/my-learnings/${courseId}`, { state: null });
        }
    };

    const videoPauseHandler = async (event) => {
        const { seconds } = event;
        setvideoProgress(seconds)
        const payload = {
            progress: seconds,
            taskId: learningData?.data?.taskList?.[currentVideoIndex].id,
        };
        const response = await VideoProgress.mutateAsync(payload);
        if (response.status) {
            await refetchLearningData();
        }
    };
    const taskClickHandler = (vidId) => {
        // setCurrentVideoIndex(vidIndex)
        const index = learningData?.data?.taskList?.findIndex((video) => video.id === vidId);
        setCurrentVideoIndex(index)
    }
    const videoPlayHandler = async (event) => {
        const { seconds, percent, duration } = event;
        setvideoProgress(seconds)
        const payload = {
            taskId: learningData?.data?.taskList?.[currentVideoIndex].id,
        };
        const response = await CurrentVideo.mutateAsync(payload);
        if (response.status) {
            await refetchLearningData();
        }
    };
    const videoEndHandler = async () => {
        await CompleteTask.mutateAsync({ taskId: learningData?.data?.taskList?.[currentVideoIndex].id })
    }


    const currentVideo = learningData?.data?.taskList?.[currentVideoIndex];
    console.log("==currentVideo===",currentVideo)
    useEffect(() => {
        setTaskId(currentVideo?.id)
    }, [learningData?.data])

    return (
        <div className="mylearning_contant_section_bx">

            <div className="row">
                {
                    apiLoading ?
                        <div className='col-md-8'>
                            <Skeleton className="mylearning_video_box" height={500} />
                            <div className="video_tittle_box">
                                <h3><Skeleton width={80} /></h3>
                                <div className="navigation_buttons">
                                    <Skeleton className="btn video-prev-btn" width={100} />

                                </div>
                            </div>
                        </div>


                        :
                        <div className="col-md-8">
                            <div className="mylearning_video_box">
                                {currentVideo?.url ? (
                                    <Vimeo
                                        key={currentVideo?.url}
                                        video={currentVideo?.url}
                                        onProgress={videoProgressHandler}
                                        onPause={videoPauseHandler}
                                        onPlay={videoPlayHandler}
                                        onEnd={videoEndHandler}
                                        start={parseFloat(learningData?.data?.taskList?.[currentVideoIndex]?.progress) > 1 ? parseFloat(learningData?.data?.taskList?.[currentVideoIndex]?.progress) - 1 : parseFloat(learningData?.data?.taskList?.[currentVideoIndex]?.progress)}
                                        responsive
                                        loop={false}
                                    />
                                ) : (
                                    'Loading...'
                                )}
                            </div>
                            <div className="video_tittle_box">
                                <h3>{currentVideo?.title}</h3>
                                <div className="navigation_buttons">
                                    <button
                                        className="btn video-prev-btn"
                                        onClick={previousHandler}
                                        hidden={currentVideoIndex === 0}
                                    >
                                        Previous
                                    </button>
                                    <button
                                        className="btn btn-primary"
                                        onClick={nextHandler}
                                        hidden={currentVideoIndex === learningData?.data?.taskList?.length - 1}
                                    >
                                        Next
                                    </button>
                                </div>
                            </div>
                        </div>
                }
                {
                    apiLoading ?
                        <div className="col-md-4">
                            {
                                Array.from({ length: 3 }).map((_, index) => (
                                    <>
                                        <h2><Skeleton width={100} /></h2>
                                        <p><Skeleton /></p>
                                        <div style={{ borderBottom: '1px solid #f3f3f3' }} className='mb-1' />
                                    </>

                                ))
                            }

                        </div>

                        :
                        <div className="col-md-4">
                            <div className="accordion accordion-flush moduleListACCRD" id="accordionFlushExample">

                                {learningData?.data?.videos?.modules?.map((item, index) => {
                                    const totalSeconds = parseInt(item.duration, 10);
                                    const hours = Math.floor(totalSeconds / 3600);
                                    const remainingSecondsAfterHours = totalSeconds % 3600;
                                    const minutes = Math.floor(remainingSecondsAfterHours / 60);
                                    const remainingSeconds = remainingSecondsAfterHours % 60;
                                    return (
                                        <div className="accordion-item" key={index}>
                                            <h2 className="accordion-header">
                                                <button
                                                    className="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target={`#flush-collapse${index}`}
                                                    aria-expanded="false"
                                                    aria-controls={`#flush-collapse${index}`}
                                                >
                                                    <span>
                                                        <strong>{item.name}</strong>
                                                        <p>
                                                            Module {index + 1} |{" "}
                                                            {hours > 0 && `${hours} Hour${hours !== 1 ? 's' : ''} `}
                                                            {minutes > 0 && `${minutes} Minute${minutes !== 1 ? 's' : ''} `}
                                                            {remainingSeconds > 0 && `${remainingSeconds} Second${remainingSeconds !== 1 ? 's' : ''}`}
                                                        </p>
                                                    </span>
                                                </button>
                                            </h2>
                                            <div
                                                id={`flush-collapse${index}`}
                                                className="accordion-collapse collapse"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    <ul>
                                                        {item?.tasks?.map((task, taskIndex) => {
                                                            console.log("==taskIndex=", taskIndex)
                                                            const taskSeconds = parseInt(task.duration, 10);
                                                            const taskHours = Math.floor(taskSeconds / 3600);
                                                            const taskRemainingSeconds = taskSeconds % 3600;
                                                            const taskMinutes = Math.floor(taskRemainingSeconds / 60);
                                                            const taskRemainingSecondsFinal = taskRemainingSeconds % 60;

                                                            return (
                                                                <li key={taskIndex}>
                                                                    <a href="#/">
                                                                        <span
                                                                            className={`${learningData?.data?.taskList?.[currentVideoIndex].id === task.id ? 'task_title_active' : 'task_title'}`}
                                                                            onClick={() => taskClickHandler(task?.id)}
                                                                        >
                                                                            {task.title}
                                                                        </span>{' '}
                                                                        <span>
                                                                            {taskHours > 0 && `${taskHours} Hour${taskHours !== 1 ? 's' : ''} `}
                                                                            {taskMinutes > 0 && `${taskMinutes} Minute${taskMinutes !== 1 ? 's' : ''} `}
                                                                            {taskRemainingSecondsFinal > 0 && `${taskRemainingSecondsFinal} Second${taskRemainingSecondsFinal !== 1 ? 's' : ''}`}
                                                                        </span>
                                                                    </a>
                                                                </li>
                                                            );
                                                        })}
                                                    </ul>
                                                </div>

                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                }
            </div>
        </div>
    )
}

export default CourseVideoSection