import React from 'react';
import { createBrowserRouter, Navigate } from 'react-router-dom';
import AuthLayout from './layouts/AuthLayout';
import MainLayout from './layouts/MainLayout';
import ContactUs from './view/Company/ContactUs';
import Help from './view/Company/Help';
import Courses from './view/Home/Courses';
import Homepage from './view/Home/Homepage';
import Categories from './view/Home/Categories';
import CourseDetails from './view/Home/CourseDetails';
import Login from './view/Auth/Login';
import Register from './view/Auth/Register';
import Account from './view/Student/Account';
import Learnings from './view/Student/Learnings';
import LearningsSingleView from './view/Student/LearningsSingleView';
import NotFound from './view/NotFound/NotFound';
import { useAuth } from './context/AuthContext';
import { useSelector } from 'react-redux'
import Cart from './components/Course/Cart/Cart';
import Chat from './view/Student/Chat';
import PaymentGatewayContainer from './view/payment/paymentGatewayContainer';
import Notifications from './components/Student/Account/Notifications';
import ErrorBoundary from './Error/ErrorBoundary';
import PurchaseInvoice from './components/Student/Account/PurchaseInvoice';


const AppRoutes = () => {

  const { isAuthenticated } = useAuth();
  const publicRoutes = [
    {
      path: '/',
      element: <AuthLayout />,
      errorElement: <ErrorBoundary />,
      children: [
        { path: '/', element: <Homepage /> },
        { path: 'categories', element: <Categories /> },
        { path: 'help', element: <Help /> },
        { path: 'contact-us', element: <ContactUs /> },
        { path: 'courses', element: <Courses /> },
        { path: 'course-details/:id', element: <CourseDetails /> },
        { path: 'login', element: <Login /> },
        { path: 'register', element: <Register /> },
        { path: '*', element: <Navigate to="/" replace /> },
      ],
    },
  ];

  const privateRoutes = [
    {
      path: '/',
      element: <MainLayout />,
      errorElement: <ErrorBoundary />,
      children: [
        { path: '/', element: <Navigate to="/my-account" replace /> },
        { path: 'my-account', element: <Account /> },
        { path: 'my-learnings', element: <Learnings /> },
        { path: 'my-learnings/:id', element: <LearningsSingleView /> },
        { path: 'courses', element: <Courses /> },
        { path: 'course-details/:id', element: <CourseDetails /> },
        { path: 'cart', element: <Cart /> },
        { path: 'categories', element: <Categories /> },
        { path: 'notifications', element: <Notifications /> },
        { path: 'help', element: <Help /> },
        { path: 'contact-us', element: <ContactUs /> },
        { path: 'chat', element: <Chat /> },
        { path: 'payment', element: <PaymentGatewayContainer /> },
        { path: 'invoice/:id', element: <PurchaseInvoice /> },
        { path: '*', element: <Navigate to="/my-account" replace /> },
      ],
    },
  ];

  return createBrowserRouter(isAuthenticated ? privateRoutes : publicRoutes);
};

export default AppRoutes;
