import React, { useEffect } from 'react'
import { useRouteError } from 'react-router-dom'
import Header from '../common/Header';
import Footer from '../common/Footer';
import errorGif from '../assets/images/404 error lost in space.gif'
import Aos from 'aos';
import { useNavigate } from 'react-router-dom'
import {Icon} from '@iconify/react'
function ErrorBoundary() {
  const navigate = useNavigate()
  const error = useRouteError();
  useEffect(() => {
    Aos.init()
  }, [])

  const backTohomeHandler = () => {
    navigate('/my-account')
  }

  return (
    <>
      <Header />
      <section className="home_category_section">
        <div className="container">
          <div className="main_heading_txt">
            <div className="row align-items-center">
              {/* <div className="col-md-6" data-aos="fade-up">
                <span style={{fontSize:'60px'}}><label><strong>Oops!</strong></label></span>
                <h4 style={{ color: '#1d5be89e' }}>Something went wrong...</h4>
                <button onClick={backTohomeHandler} className='backTphomebtn'>Back to dashboard</button>
              </div> */}
              <div className="col-md-6" data-aos="fade-up">
                <h2>
                  <span style={{ lineHeight: '2' }}>Oops....</span><br />
                  <strong>Something went wrong <Icon icon="streamline:sad-face" width="24" height="24" style={{ color: '#1d5be8' }} /></strong>
                </h2>
                <p>We encountered an error while processing your request. Please try again later or contact support if the issue persists.</p>

              </div>

              <div className="col-md-6">

                <img src={errorGif} />
              </div>
            </div>
          </div>
          <div className="row mt-5">
          </div>
        </div>

      </section>
      <Footer />
    </>

  );
}

export default ErrorBoundary