import React, { useState } from 'react'
import InnerPageHeader from '../../../common/InnerPageHeader'
import CourseVideoSection from './CourseVideoSection'
import CourseStatusBox from './CourseStatusBox'
import CourseOverview from './CourseOverview'
import CourseInstructorDetails from './CourseInstructorDetails'
import Skeleton from 'react-loading-skeleton'


function SingleView({ learningData, learningNotes, refetchLearningData, courseId, apiLoading }) {
    const [taskId, setTaskId] = useState(null)
    console.log("====taskid==df=",taskId)
    return (
        <>
            <InnerPageHeader
                title='My Learnings'
                subtitile='My Account'
                titlePath='my-learnings'
                subTitlePath='my-account'
            />
            <section class="mylearning_contant_section">

                <div class="container">
                    <h4 style={{ textTransform: 'capitalize' }}>{apiLoading ? <Skeleton width={80} /> : learningData?.data?.courseDetails?.title}</h4>

                    <CourseVideoSection
                        learningData={learningData}
                        refetchLearningData={refetchLearningData}
                        courseId={courseId}
                        apiLoading={apiLoading}
                        setTaskId={setTaskId}
                    />
                    <CourseStatusBox
                        learningData={learningData}
                        courseId={courseId}
                        refetchLearningData={refetchLearningData}
                        apiLoading={apiLoading}
                    />
                </div>
            </section>
            <section class="course_content_sec">
                <div class="container">
                    <div class="row">
                        <CourseOverview
                            learningData={learningData}
                            learningNotes={learningNotes}
                            apiLoading={apiLoading}
                            taskId={taskId}
                        />
                        <CourseInstructorDetails
                            learningData={learningData}
                            apiLoading={apiLoading}
                        />
                    </div>
                </div>
            </section>
        </>
    )
}

export default SingleView