import { useQuery,useMutation } from '@tanstack/react-query'
import { getNotification, getUserDetails, readAllnotification } from '../services/common/commonService'

const CommonApis = {
    GetUserData: () => {
        const response=useQuery({
            queryKey:["get-logged-user-details"],
            queryFn:()=>getUserDetails()
            
        })
        return response
    },
    GetNotifications:(limit,isAuthenticated)=>{
        const response=useQuery({
            queryKey:["get-notifications",limit],
            queryFn:()=>getNotification(limit),
            enabled:isAuthenticated
            
        })
        return response
    },
    ReadAllNotificatio: () => {
        const response = useMutation({
            mutationFn: (data) => readAllnotification(data)
        })
        return response
    },
}

export default CommonApis