import { useMutation, useQuery } from '@tanstack/react-query'
import { registerUser } from '../services/auth/registerService'
import { contact, getCompanydetails, getHome, login, logout } from '../services/auth/loginService'

const AuthApis = {
    RegisterUser: () => {
        const response = useMutation({
            mutationFn: (data) => registerUser(data),

        })
        return response
    },
    LoginUser: () => {
        const response = useMutation({
            mutationFn: (data) => login(data),

        })
        return response
    },
    Logout: () => {
        const response = useMutation({
            mutationFn: () => logout(),

        })
        return response
    },
    GetHome: () => {
        const response = useQuery({
            queryKey: ["get-home"],
            queryFn: () => getHome(),
            throwOnError:(err)=>{
                console.log("======data fetching error====",err)
            }

        })
        return response
    },
    GetcompanyDetails: () => {
        const response = useQuery({
            queryKey: ["get-company-details"],
            queryFn: () => getCompanydetails(),
            throwOnError:(err)=>{
                console.log("======data fetching error GetcompanyDetails====",err)
            }

        })
        return response
    },
    Contact: () => {
        const response = useMutation({
            mutationFn: (data) => contact(data),

        })
        return response
    }

}
export default AuthApis
