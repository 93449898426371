import React from 'react'

function Overview({ courseDetails }) {

    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <h4>What you’ll learn</h4>
                    <p style={{ wordWrap: 'anywhere' }}>
                        {courseDetails?.data?.course?.overview}
                    </p>
                </div>
            </div>
            {/* <div className="row mt-4">
                <div className="col-md-12">
                    <h4>This course includes:</h4>
                    <div className="row">
                        <div className="col-md-6">
                            <ul>
                                <li>Dunt ut labore et dolore ma gna aliquaim.</li>
                                <li>Dunt ut labore et dolore ma gna.</li>
                                <li>Dunt ut labore et dolore ma gna aliquaim lorem.</li>
                            </ul>
                        </div>
                        <div className="col-md-6">
                            <ul>
                                <li>Dunt ut labore et dolore ma gna aliquaim.</li>
                                <li>Dunt ut labore et dolore ma gna.</li>
                                <li>Dunt ut labore et dolore ma gna aliquaim lorem.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div> */}
        </>
    )
}

export default Overview