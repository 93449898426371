import React, { useState } from 'react'
import ProfileApis from '../../../queries/profile'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import toast from 'react-hot-toast';

export default function VerifyMail({ profileApi, show, mail, handleClose, sendVerificationMail }) {
    const verifyMailOtp = ProfileApis.VerifyMailOtp()
    const [otp, setOtp] = useState("")
    const [isValid, setIsValid] = useState(true);
    const [resend, setResend] = useState(true);

    const resendMail = () => {
        sendVerificationMail()
        setResend(false)
        setTimeout(() => {
            setResend(true)

        }, 1 * 60 * 1000);
    }
    const closeModal = () => {
        handleClose()
        setIsValid(true)
        setOtp("")
    }

    const verify = async () => {
        const valid = await validateOtp(otp)
        setIsValid(valid)
        if (!valid) return
        const otpVerified = await verifyMailOtp.mutateAsync({ otp: otp })
        if (otpVerified.status) {

            toast.success("verified")
            profileApi.refetch()
            closeModal()
        } else {
            setOtp("")
            toast.error(otpVerified.error.message)
        }
    }

    const validateOtp = async (otp) => {
        const regex = /^\d{6}$/;
        if (!regex.test(otp) || otp === '') return false
        return true
    }

    const handleOtp = (e) => {
        const { value } = e.target
        setOtp(value)
    }

    return (
        <>
            <Modal show={show} onHide={closeModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Email Verification</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{`Enter the OTP sent to `}<strong>{mail}</strong></p>
                    <div className="form-group">
                        <input
                            type="text"
                            className={`form-control ${!isValid ? 'is-invalid' : ''}`}
                            id="otp"
                            name="otp"
                            maxLength="6"
                            pattern="\d{6}"
                            placeholder="OTP"
                            required
                            onChange={handleOtp}
                            value={otp}
                        />
                        {!isValid && <div class="invalid-feedback">
                            {"invalid_otp"}
                        </div>}
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    {resend && <Button variant="secondary" onClick={resendMail}>
                        Resend
                    </Button>}
                    <Button variant="primary" onClick={verify} disabled={!isValid}>
                        Verify
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
