import React, { useState } from 'react'
import Banner from '../../components/Homepage/Banner'
import QuickCount from '../../components/Homepage/QuickCount'
import Categories from '../../components/Homepage/Categories'
import Courses from '../../components/Homepage/Courses'
import AboutUs from '../../components/Homepage/AboutUs'
import Testimonial from '../../components/Homepage/Testimonial'
import AuthApis from '../../queries/auth'
import CourseApis from '../../queries/course'

function Homepage() {
    const { data: homeDatas,status } = AuthApis.GetHome()
    const { data: categories,isLoading:catApiLoading } = CourseApis.GetCategories()
    const [activeCategory, setactiveCategory] = useState('')
    const { data: CourseDetails } = CourseApis.GetCourses(activeCategory, 3)


    return (
        <React.Fragment>
            <Banner />
            {
                homeDatas &&
                <QuickCount count={homeDatas?.data} />
            }
            <Categories categoriesList={categories} catApiLoading={catApiLoading}/>
            
            {
                CourseDetails &&
                <Courses
                    CourseDetails={CourseDetails}
                    setactiveCategory={setactiveCategory}
                    activeCategory={activeCategory}
                />
            }

            {/* <AboutUs />
            <Testimonial /> */}
        </React.Fragment>
    )
}

export default Homepage